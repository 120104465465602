import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import { salebanner } from "../assets";
import Rating from "react-rating";
import { user } from "../assets";

const studentReviews = [
  {
    name: "Sarah Johnson",
    location: "New York, USA",
    course: "Computer Science",
    review:
      "The assignment service was amazing! I received my work well before the deadline, and the quality was top-notch. Will definitely use this service again.",
    rating: 5,
    date: "2024-08-14",
  },
  {
    name: "Rajesh Patel",
    location: "Mumbai, India",
    course: "Mechanical Engineering",
    review:
      "Very reliable service! The experts understood my requirements perfectly and delivered a well-researched assignment. Helped me get an A+.",
    rating: 4.8,
    date: "2024-09-03",
  },
  {
    name: "Emily Clark",
    location: "London, UK",
    course: "Business Management",
    review:
      "Great service! The content was well-structured, and the customer support was always available to help. Highly recommend!",
    rating: 4.7,
    date: "2024-07-22",
  },
  {
    name: "Ahmed Al-Farsi",
    location: "Dubai, UAE",
    course: "Marketing",
    review:
      "I was skeptical at first, but the results were fantastic. My assignment was detailed and free of any plagiarism. Will use it again!",
    rating: 4.9,
    date: "2024-09-15",
  },
  {
    name: "Yuki Nakamura",
    location: "Tokyo, Japan",
    course: "Data Science",
    review:
      "Quick and efficient service. I was able to meet a very tight deadline thanks to them. Highly satisfied with the quality of work.",
    rating: 5,
    date: "2024-08-28",
  },
  {
    name: "Carlos Gomez",
    location: "Madrid, Spain",
    course: "Law",
    review:
      "Good service overall. My assignment was well-researched and professionally written, but I think there could have been more examples.",
    rating: 4.5,
    date: "2024-09-05",
  },
  {
    name: "Anna Müller",
    location: "Berlin, Germany",
    course: "Environmental Science",
    review:
      "The service was exceptional! The expert was knowledgeable, and the assignment was formatted perfectly. I got an A for the first time.",
    rating: 5,
    date: "2024-09-01",
  },
  {
    name: "Jacob Brown",
    location: "Sydney, Australia",
    course: "Psychology",
    review:
      "Affordable and high-quality! The team provided a well-written essay that exceeded my expectations. Great communication too.",
    rating: 4.9,
    date: "2024-08-16",
  },
  {
    name: "Laura Rossi",
    location: "Rome, Italy",
    course: "History",
    review:
      "Very happy with the results! The expert followed all my guidelines and delivered a thoroughly researched paper. Definitely recommend.",
    rating: 4.8,
    date: "2024-07-30",
  },
  {
    name: "Omar Hassan",
    location: "Cairo, Egypt",
    course: "Electrical Engineering",
    review:
      "This service saved me during a tight deadline! The assignment was perfect and helped boost my grade significantly.",
    rating: 5,
    date: "2024-09-12",
  },
  {
    name: "Sophia Nguyen",
    location: "Toronto, Canada",
    course: "Finance",
    review:
      "Great quality work and fast delivery. I’m impressed with how they handled my case study. Will use them for future assignments.",
    rating: 4.9,
    date: "2024-08-20",
  },
  {
    name: "Lucas Martin",
    location: "Paris, France",
    course: "Political Science",
    review:
      "The team was very responsive, and they understood my needs perfectly. The only downside was a small delay in delivery, but the quality was worth it.",
    rating: 4.6,
    date: "2024-09-07",
  },
  {
    name: "Fatima Begum",
    location: "Lahore, Pakistan",
    course: "Medicine",
    review:
      "Excellent service! The content was accurate, and I received exactly what I needed. The experts really know their stuff.",
    rating: 5,
    date: "2024-08-11",
  },
  {
    name: "Thomas Lee",
    location: "Los Angeles, USA",
    course: "Graphic Design",
    review:
      "This service really helped me out! They provided a high-quality, well-designed project that exceeded my expectations. Will recommend it to friends.",
    rating: 5,
    date: "2024-07-25",
  },
  {
    name: "Aisha Khan",
    location: "Karachi, Pakistan",
    course: "Chemistry",
    review:
      "The assignment was very well done, with great attention to detail. I appreciate the fast turnaround and great communication from the team.",
    rating: 4.7,
    date: "2024-09-10",
  },
];

export function HomeSaleSection() {
  const [slidesPerView, setSlidesPerView] = useState(3);

  useEffect(() => {
    function updateSlidesPerView() {
      if (window.innerWidth < 700) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 1000) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(3);
      }
    }
    updateSlidesPerView();
    window.addEventListener("resize", updateSlidesPerView);

    return () => {
      window.removeEventListener("resize", updateSlidesPerView);
    };
  }, []);

  const totalSlides = 16; // Double the number of slides for seamless looping

  return (
    <div className="home__sale overflow-hidden">
      <div
        style={{
          textAlign: "center",
          width: "100%",
        }}
        className="home__industry__heading"
      >
        What <span>students</span> are saying
      </div>
      <Swiper
        modules={[Autoplay]}
        slidesPerView={slidesPerView}
        spaceBetween={30}
        loop={true}
        speed={5000}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
          reverseDirection: false,
        }}
        allowTouchMove={false}
        className="mySwiper"
      >
        {studentReviews.map((review, idx) => (
          <SwiperSlide key={idx}>
            <div className="home__sale__entry">
              <div className="home__sale__entry__header">
                <div className="home__sale__entry__header__name">
                  {review.name}
                </div>
                <span className="home__sale__entry__header__subject">
                  {review.course}
                </span>{" "}
                <div className="home__sale__entry__header__rating">
                  <Rating
                    readonly
                    fractions={10}
                    initialRating={4}
                    emptySymbol={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.63"
                        height="20.103"
                        viewBox="0 0 12.63 12.103"
                      >
                        <path
                          id="star"
                          d="M12.6,5.065a.67.67,0,0,0-.578-.462L8.374,4.273,6.932.9A.671.671,0,0,0,5.7.9L4.256,4.272.61,4.6A.672.672,0,0,0,.229,5.778L2.985,8.195l-.812,3.579a.67.67,0,0,0,1,.725L6.315,10.62,9.458,12.5a.671.671,0,0,0,1-.725L9.645,8.195,12.4,5.779a.672.672,0,0,0,.2-.714Zm0,0"
                          transform="translate(0 -0.491)"
                          fill="#b5c3c3"
                        />
                      </svg>
                    }
                    fullSymbol={
                      <svg
                        className="fill__symbol"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.63"
                        height="20.103"
                        viewBox="0 0 12.63 12.103"
                        fill="currentColor"
                      >
                        <path
                          id="star"
                          d="M12.6,5.065a.67.67,0,0,0-.578-.462L8.374,4.273,6.932.9A.671.671,0,0,0,5.7.9L4.256,4.272.61,4.6A.672.672,0,0,0,.229,5.778L2.985,8.195l-.812,3.579a.67.67,0,0,0,1,.725L6.315,10.62,9.458,12.5a.671.671,0,0,0,1-.725L9.645,8.195,12.4,5.779a.672.672,0,0,0,.2-.714Zm0,0"
                          transform="translate(0 -0.491)"
                        />
                      </svg>
                    }
                  />
                  ({review.rating})
                </div>
              </div>
              <div className="home__sale__entry__content">{review.review}</div>
              <div className="home__sale__entry__footer">{review.location}</div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

// {
//   name: "Aisha Khan",
//   location: "Karachi, Pakistan",
//   course: "Chemistry",
//   review:
//     "The assignment was very well done, with great attention to detail. I appreciate the fast turnaround and great communication from the team.",
//   rating: 4.7,
//   date: "2024-09-10",
// },
