import React from "react";
import { Zoom } from "react-reveal";
import { homeSolutionsData } from "../constants/homeSolutionsData";
import { Link } from "react-router-dom";

export function HomeSolutionsSection() {
  return (
    <div className="home__solutions">
      <div className="home__solutions__heading">How Our Services Work?</div>
      <div className="home__solutions__content">
        {homeSolutionsData.map((solution) => (
          <Zoom>
            <a
              href="#"
              key={JSON.stringify(solution)}
              className="home__solutions__content__entry"
            >
              <div className="home__solutions__content__entry__icon">
                {solution.icon}
              </div>
              <div className="home__solutions__content__entry__text">
                {solution.label}
              </div>
              <div className="home__solutions__content__entry__info">
                {solution.info}
              </div>
            </a>
          </Zoom>
        ))}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "1em",
        }}
      >
        <Link
          to="#"
          className="header__content__nav__buttons__button header__content__nav__buttons__button__secondary"
        >
          Book Our Service
        </Link>
      </div>
    </div>
  );
}
