import React from "react";
import { aboutjumbotron } from "../assets";
import { Link } from "react-router-dom";

export function AboutJumbotronSection() {
  return (
    <div className="about__jumbotron">
      {/* <img
        src={aboutjumbotron}
        alt="aboutjumbotron"
        className="about__jumbotron__img"
      /> */}
      <div className="about__jumbotron__overlay">
        <div className="about__jumbotron__overlay__heading">About Us</div>
        <div className="about__jumbotron__overlay__info">
          ReadyAssignment is a comprehensive platform and marketplace designed
          to connect students and professionals with expert academic help.
          Whether you're a student needing assistance with essays, research
          papers, homework, or a corporation looking for specialized training
          content, ReadyAssignment provides skilled services to meet your needs.
          Our network includes experienced tutors, writers, researchers,
          editors, proofreaders, and academic consultants, ready to offer their
          expertise for a wide range of subjects and disciplines.
        </div>
        <div className="about__jumbotron__overlay__buttons">
          <Link
            to="/"
            className="about__jumbotron__overlay__buttons__link about__jumbotron__overlay__buttons__link__primary"
          >
            More
          </Link>
          {/* <Link
            to="/"
            className="about__jumbotron__overlay__buttons__link about__jumbotron__overlay__buttons__link__secondary"
          >
            Services
          </Link> */}
        </div>
      </div>
    </div>
  );
}
