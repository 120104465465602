import React from "react";
import { Zoom } from "react-reveal";
import { joinourteam } from "../assets";

export function HomeJoinTeamSection() {
  return (
    <div className="home__join__team">
      <img
        src={joinourteam}
        alt="joinourteam"
        className="home__join__team__img"
      />
      <Zoom>
        <div className="home__join__team__overlay">
          <div className="home__join__team__overlay__slogan">
            Join Our Expert Team
          </div>
          <div className="home__join__team__overlay__sub__heading">
            We Deliver
          </div>
          <div className="home__join__team__overlay__heading">
            <span>Top-Notch Academic Assistance</span> for your success
          </div>
          <div className="home__join__team__overlay__info">
            Our platform is your go-to solution for academic support, connecting
            students with experienced tutors and subject matter experts. We
            offer help in a wide range of subjects including Math, Science,
            Engineering, Literature, Business, and more. Whether it's homework,
            assignments, projects, or exam prep, our team is here to assist
            students at every level, ensuring timely and quality results for
            all.
          </div>
        </div>
      </Zoom>
    </div>
  );
}
