import React, { useState } from "react";
import { homejumbotron } from "../assets";
import { Fade } from "react-reveal";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";

export function HomeJumbotronSection() {
  const [selectedService, setSelectedService] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedPages, setSelectedPages] = useState({
    value: 5,
    label: "5 Pages / 1250 Words",
    price: 8.91,
  });
  const [selectedTimeframe, setSelectedTimeframe] = useState({
    value: "1 Day",
    label: "1 Day",
    price: 2.16,
  });
  const price = parseFloat(
    (selectedService?.price ?? 0) +
      (selectedSubject?.price ?? 0) +
      (selectedPages?.price ?? 0) +
      (selectedTimeframe?.price ?? 0)
  ).toFixed(2);

  const discountPercentage = 25;

  const discountAmount = (price * discountPercentage) / 100;

  const finalPrice = parseFloat(price - discountAmount).toFixed(2);

  console.table([price, finalPrice]);

  return (
    <div className="home__jumbotron">
      <img
        src={homejumbotron}
        alt="homejumbotron"
        className="home__jumbotron__img"
      />
      <div className="home__jumbotron__overlay">
        <div className="home__jumbotron__overlay__content">
          <div className="home__jumbotron__overlay__content__left">
            <div className="home__jumbotron__overlay__content__heading">
              <Fade>Best Assignment Help Online</Fade>
            </div>
            <div className="home__jumbotron__overlay__content__info">
              <Fade>
                Comprehensive Assignment Assistance With a No-AI Guarantee!
              </Fade>
            </div>
            {/* <div className="home__jumbotron__overlay__content__buttons">
            <a
              href="#"
              className="home__jumbotron__overlay__content__buttons__link"
            >
              <Zoom>
                <img
                  src={appstore}
                  alt="appstore"
                  className="home__jumbotron__overlay__content__buttons__link__img"
                />
              </Zoom>
            </a>
            <a
              href="#"
              className="home__jumbotron__overlay__content__buttons__link"
            >
              <Zoom>
                <img
                  src={googleplay}
                  alt="googleplay"
                  className="home__jumbotron__overlay__content__buttons__link__img"
                />
              </Zoom>
            </a>
          </div> */}
            <div className="home__jumbotron__overlay__content__features">
              <div className="home__jumbotron__overlay__content__features__entry">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-clipboard-list"
                >
                  <rect width="8" height="4" x="8" y="2" rx="1" ry="1" />
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2" />
                  <path d="M12 11h4" />
                  <path d="M12 16h4" />
                  <path d="M8 11h.01" />
                  <path d="M8 16h.01" />
                </svg>
                Plagiarism Free
              </div>
              <div className="home__jumbotron__overlay__content__features__entry">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-repeat-2"
                >
                  <path d="m2 9 3-3 3 3" />
                  <path d="M13 18H7a2 2 0 0 1-2-2V6" />
                  <path d="m22 15-3 3-3-3" />
                  <path d="M11 6h6a2 2 0 0 1 2 2v10" />
                </svg>
                Free Revision
              </div>
              <div className="home__jumbotron__overlay__content__features__entry">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="1"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-headset"
                >
                  <path d="M3 11h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-5Zm0 0a9 9 0 1 1 18 0m0 0v5a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3Z" />
                  <path d="M21 16v2a4 4 0 0 1-4 4h-5" />
                </svg>
                24x7 Support
              </div>
            </div>
          </div>
          <div className="home__jumbotron__overlay__content__right">
            <div className="home__jumbotron__overlay__content__right__card">
              <div className="home__jumbotron__overlay__content__right__card__header">
                <h3>Trusted Assignment Help</h3>
                <p>Buy Assignment Writing Help Online</p>
              </div>
              <div className="home__jumbotron__overlay__content__right__card__content">
                <div className="home__jumbotron__overlay__content__right__card__content__row">
                  <div className="home__jumbotron__overlay__content__right__card__content__row__entry">
                    <ReactSelect
                      classNamePrefix={"react__select"}
                      options={[
                        {
                          value: "Assignment",
                          label: "Assignment",
                          price: 10.79, // 3000 PKR / 277.856
                        },
                        {
                          value: "Coursework",
                          label: "Coursework",
                          price: 14.39, // 4000 PKR / 277.856
                        },
                        {
                          value: "Homework",
                          label: "Homework",
                          price: 8.99, // 2500 PKR / 277.856
                        },
                        {
                          value: "Term Paper",
                          label: "Term Paper",
                          price: 17.99, // 5000 PKR / 277.856
                        },
                        {
                          value: "Research Paper",
                          label: "Research Paper",
                          price: 21.59, // 6000 PKR / 277.856
                        },
                        {
                          value: "Research Proposal",
                          label: "Research Proposal",
                          price: 16.19, // 4500 PKR / 277.856
                        },
                        {
                          value: "Personal Statement",
                          label: "Personal Statement",
                          price: 12.59, // 3500 PKR / 277.856
                        },
                        {
                          value: "Case Study",
                          label: "Case Study",
                          price: 19.79, // 5500 PKR / 277.856
                        },
                        {
                          value: "Report Writing",
                          label: "Report Writing",
                          price: 14.39, // 4000 PKR / 277.856
                        },
                        {
                          value: "Dissertation",
                          label: "Dissertation",
                          price: 35.99, // 10000 PKR / 277.856
                        },
                        {
                          value: "PHD Thesis",
                          label: "PHD Thesis",
                          price: 53.99, // 15000 PKR / 277.856
                        },
                        {
                          value: "Dissertation Proposal",
                          label: "Dissertation Proposal",
                          price: 25.19, // 7000 PKR / 277.856
                        },
                        {
                          value: "Editing | Proofreading",
                          label: "Editing | Proofreading",
                          price: 10.79, // 3000 PKR / 277.856
                        },
                        {
                          value: "Paraphrasing",
                          label: "Paraphrasing",
                          price: 7.19, // 2000 PKR / 277.856
                        },
                        {
                          value: "Business Plan",
                          label: "Business Plan",
                          price: 28.79, // 8000 PKR / 277.856
                        },
                        {
                          value: "Essay",
                          label: "Essay",
                          price: 12.59, // 3500 PKR / 277.856
                        },
                        {
                          value: "Resume / CV Services",
                          label: "Resume / CV Services",
                          price: 8.99, // 2500 PKR / 277.856
                        },
                        {
                          value: "PowerPoint Presentation",
                          label: "PowerPoint Presentation",
                          price: 10.79, // 3000 PKR / 277.856
                        },
                        {
                          value: "Plagiarism Report",
                          label: "Plagiarism Report",
                          price: 5.39, // 1500 PKR / 277.856
                        },
                        {
                          value: "Poster",
                          label: "Poster",
                          price: 7.19, // 2000 PKR / 277.856
                        },
                        {
                          value: "Leaflet",
                          label: "Leaflet",
                          price: 5.39, // 1500 PKR / 277.856
                        },
                        {
                          value: "Online Exam | Question based test Papers",
                          label: "Online Exam | Question based test Papers",
                          price: 17.99, // 5000 PKR / 277.856
                        },
                        {
                          value: "Multiple Choice Questions",
                          label: "Multiple Choice Questions",
                          price: 10.79, // 3000 PKR / 277.856
                        },
                        {
                          value: "Summary Writing",
                          label: "Summary Writing",
                          price: 8.99, // 2500 PKR / 277.856
                        },
                        {
                          value: "Portfolio",
                          label: "Portfolio",
                          price: 21.59, // 6000 PKR / 277.856
                        },
                      ]}
                      value={selectedService}
                      onChange={(value) => setSelectedService(value)}
                      placeholder="Select Services"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#3CB14ACC",
                          primary50: "#3CB14A99",
                          primary25: "#3CB14A66",
                          primary: "#3CB14A ",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="home__jumbotron__overlay__content__right__card__content__row">
                  <div className="home__jumbotron__overlay__content__right__card__content__row__entry">
                    <ReactSelect
                      classNamePrefix={"react__select"}
                      options={[
                        {
                          label: "Management",
                          options: [
                            {
                              value: "Economics (theory)",
                              label: "Economics (theory)",
                              price: 14.4,
                            },
                            {
                              value: "Leisure Management",
                              label: "Leisure Management",
                              price: 12.6,
                            },
                            {
                              value: "Human Resource",
                              label: "Human Resource",
                              price: 16.2,
                            },
                            {
                              value: "Housing",
                              label: "Housing",
                              price: 10.8,
                            },
                            {
                              value: "Operations",
                              label: "Operations",
                              price: 18.01,
                            },
                            {
                              value: "Fashion",
                              label: "Fashion",
                              price: 14.4,
                            },
                            {
                              value: "Estate Management",
                              label: "Estate Management",
                              price: 19.81,
                            },
                            {
                              value: "Human Resource Management",
                              label: "Human Resource Management",
                              price: 18.01,
                            },
                            {
                              value: "Reflective",
                              label: "Reflective",
                              price: 12.6,
                            },
                            {
                              value: "Other Management",
                              label: "Other Management",
                              price: 10.8,
                            },
                            {
                              value: "Marketing",
                              label: "Marketing",
                              price: 21.61,
                            },
                            {
                              value: "Leadership",
                              label: "Leadership",
                              price: 18.01,
                            },
                            {
                              value: "Research Methodology",
                              label: "Research Methodology",
                              price: 25.21,
                            },
                            {
                              value: "Communication",
                              label: "Communication",
                              price: 10.8,
                            },
                            {
                              value: "Human Rights",
                              label: "Human Rights",
                              price: 14.4,
                            },
                            {
                              value: "International Relations",
                              label: "International Relations",
                              price: 19.81,
                            },
                            {
                              value: "Business",
                              label: "Business",
                              price: 18.01,
                            },
                            {
                              value: "International Studies",
                              label: "International Studies",
                              price: 21.61,
                            },
                            {
                              value: "Information Systems",
                              label: "Information Systems",
                              price: 19.81,
                            },
                            {
                              value: "Project Management",
                              label: "Project Management",
                              price: 25.21,
                            },
                            {
                              value: "Economics (calculative)",
                              label: "Economics (calculative)",
                              price: 16.2,
                            },
                            {
                              value: "Small Business Enterprise",
                              label: "Small Business Enterprise",
                              price: 21.61,
                            },
                          ],
                        },
                        {
                          label: "Finance",
                          options: [
                            {
                              value: "Statistics",
                              label: "Statistics",
                              price: 18.01,
                            },
                            {
                              value: "Financial Management",
                              label: "Financial Management",
                              price: 25.21,
                            },
                            {
                              value: "Accounts",
                              label: "Accounts",
                              price: 14.4,
                            },
                            {
                              value: "Commerce",
                              label: "Commerce",
                              price: 16.2,
                            },
                            {
                              value: "Eviews",
                              label: "Eviews",
                              price: 19.81,
                            },
                            {
                              value: "SAS",
                              label: "SAS",
                              price: 21.61,
                            },
                            {
                              value: "NVIVO",
                              label: "NVIVO",
                              price: 25.21,
                            },
                            {
                              value: "SPSS",
                              label: "SPSS",
                              price: 18.01,
                            },
                          ],
                        },
                        {
                          label: "Tourism",
                          options: [
                            {
                              value: "Travel & Tourism",
                              label: "Travel & Tourism",
                              price: 14.4,
                            },
                            {
                              value: "Food",
                              label: "Food",
                              price: 12.6,
                            },
                            {
                              value: "Tourism",
                              label: "Tourism",
                              price: 16.2,
                            },
                            {
                              value: "Hospitality",
                              label: "Hospitality",
                              price: 18.01,
                            },
                          ],
                        },
                        {
                          label: "Law",
                          options: [
                            {
                              value: "Law",
                              label: "Law",
                              price: 18.01,
                            },
                            {
                              value: "Legal Aspects",
                              label: "Legal Aspects",
                              price: 21.61,
                            },
                            {
                              value: "Legal",
                              label: "Legal",
                              price: 19.81,
                            },
                            {
                              value: "Business Law",
                              label: "Business Law",
                              price: 21.61,
                            },
                          ],
                        },
                        {
                          label: "Health",
                          options: [
                            {
                              value: "Health",
                              label: "Health",
                              price: 16.2,
                            },
                            {
                              value: "Healthcare",
                              label: "Healthcare",
                              price: 19.81,
                            },
                            {
                              value: "Health & Social Care",
                              label: "Health & Social Care",
                              price: 21.61,
                            },
                            {
                              value: "Pharmacy",
                              label: "Pharmacy",
                              price: 18.01,
                            },
                            {
                              value: "Medical",
                              label: "Medical",
                              price: 23.41,
                            },
                            {
                              value: "Childcare",
                              label: "Childcare",
                              price: 14.4,
                            },
                            {
                              value: "Nursing",
                              label: "Nursing",
                              price: 21.61,
                            },
                            {
                              value: "Physiology",
                              label: "Physiology",
                              price: 18.01,
                            },
                            {
                              value: "Physical Education",
                              label: "Physical Education",
                              price: 16.2,
                            },
                            {
                              value: "Biotechnology",
                              label: "Biotechnology",
                              price: 25.21,
                            },
                            {
                              value: "Lab-Report",
                              label: "Lab-Report",
                              price: 14.4,
                            },
                          ],
                        },
                        {
                          label: "Arts & Humanities",
                          options: [
                            {
                              value: "Social Policy",
                              label: "Social Policy",
                              price: 18.01,
                            },
                            {
                              value: "Social Work",
                              label: "Social Work",
                              price: 19.81,
                            },
                            {
                              value: "Sociology",
                              label: "Sociology",
                              price: 16.2,
                            },
                            {
                              value: "Sports",
                              label: "Sports",
                              price: 14.4,
                            },
                            {
                              value: "Military",
                              label: "Military",
                              price: 21.61,
                            },
                            {
                              value: "Theology",
                              label: "Theology",
                              price: 18.01,
                            },
                            {
                              value: "Psychology",
                              label: "Psychology",
                              price: 19.81,
                            },
                            {
                              value: "Property",
                              label: "Property",
                              price: 18.01,
                            },
                            {
                              value: "Arts",
                              label: "Arts",
                              price: 16.2,
                            },
                            {
                              value: "Criminology",
                              label: "Criminology",
                              price: 18.01,
                            },
                            {
                              value: "Environmental Studies",
                              label: "Environmental Studies",
                              price: 19.81,
                            },
                            {
                              value: "General Studies",
                              label: "General Studies",
                              price: 12.6,
                            },
                            {
                              value: "Geography",
                              label: "Geography",
                              price: 16.2,
                            },
                            {
                              value: "History",
                              label: "History",
                              price: 14.4,
                            },
                            {
                              value: "Anthropology",
                              label: "Anthropology",
                              price: 18.01,
                            },
                            {
                              value: "Religion",
                              label: "Religion",
                              price: 14.4,
                            },
                            {
                              value: "Politics",
                              label: "Politics",
                              price: 18.01,
                            },
                            {
                              value: "Philosophy",
                              label: "Philosophy",
                              price: 16.2,
                            },
                            {
                              value: "Geology",
                              label: "Geology",
                              price: 21.61,
                            },
                          ],
                        },
                        {
                          label: "Education",
                          options: [
                            {
                              value: "Translation",
                              label: "Translation",
                              price: 14.4,
                            },
                            {
                              value: "Education",
                              label: "Education",
                              price: 12.6,
                            },
                            {
                              value: "Teaching",
                              label: "Teaching",
                              price: 14.4,
                            },
                          ],
                        },
                        {
                          label: "Languages",
                          options: [
                            {
                              value: "Linguistics",
                              label: "Linguistics",
                              price: 16.2,
                            },
                            {
                              value: "English Literature",
                              label: "English Literature",
                              price: 18.01,
                            },
                            {
                              value: "English Language",
                              label: "English Language",
                              price: 14.4,
                            },
                            {
                              value: "German",
                              label: "German",
                              price: 19.81,
                            },
                            {
                              value: "Languages",
                              label: "Languages",
                              price: 16.2,
                            },
                            {
                              value: "Portuguese",
                              label: "Portuguese",
                              price: 18.01,
                            },
                            {
                              value: "Spanish",
                              label: "Spanish",
                              price: 18.01,
                            },
                          ],
                        },
                        {
                          label: "Culture & Drama",
                          options: [
                            {
                              value: "Theatre",
                              label: "Theatre",
                              price: 16.2,
                            },
                            {
                              value: "Journalism",
                              label: "Journalism",
                              price: 18.01,
                            },
                            {
                              value: "Film Studies",
                              label: "Film Studies",
                              price: 19.81,
                            },
                            {
                              value: "Photography",
                              label: "Photography",
                              price: 16.2,
                            },
                            {
                              value: "Film Review",
                              label: "Film Review",
                              price: 14.4,
                            },
                            {
                              value: "Drama",
                              label: "Drama",
                              price: 18.01,
                            },
                            {
                              value: "Cultural Studies",
                              label: "Cultural Studies",
                              price: 19.81,
                            },
                            {
                              value: "Media",
                              label: "Media",
                              price: 21.61,
                            },
                            {
                              value: "Music",
                              label: "Music",
                              price: 18.01,
                            },
                          ],
                        },
                        {
                          label: "Science",
                          options: [
                            {
                              value: "Mechanics",
                              label: "Mechanics",
                              price: 21.61,
                            },
                            {
                              value: "Chemistry",
                              label: "Chemistry",
                              price: 19.81,
                            },
                            {
                              value: "Other Science",
                              label: "Other Science",
                              price: 18.01,
                            },
                            {
                              value: "Physics",
                              label: "Physics",
                              price: 21.61,
                            },
                            {
                              value: "Mathematics",
                              label: "Mathematics",
                              price: 18.01,
                            },
                            {
                              value: "Information Technology",
                              label: "Information Technology",
                              price: 19.81,
                            },
                            {
                              value: "Computer Science",
                              label: "Computer Science",
                              price: 21.61,
                            },
                            {
                              value: "Biology",
                              label: "Biology",
                              price: 19.81,
                            },
                            {
                              value: "Architecture",
                              label: "Architecture",
                              price: 23.41,
                            },
                            {
                              value: "Construction",
                              label: "Construction",
                              price: 21.61,
                            },
                          ],
                        },
                        {
                          label: "Programming",
                          options: [
                            {
                              value: "IT-Networking",
                              label: "IT-Networking",
                              price: 25.21,
                            },
                            {
                              value: "Operating System",
                              label: "Operating System",
                              price: 21.61,
                            },
                            {
                              value: "Data Mining",
                              label: "Data Mining",
                              price: 28.81,
                            },
                            {
                              value: "MATLAB",
                              label: "MATLAB",
                              price: 25.21,
                            },
                            {
                              value: "Linux",
                              label: "Linux",
                              price: 19.81,
                            },
                            {
                              value: "Python",
                              label: "Python",
                              price: 21.61,
                            },
                            {
                              value: "Other Programming",
                              label: "Other Programming",
                              price: 18.01,
                            },
                            {
                              value: "iOS Application",
                              label: "iOS Application",
                              price: 28.81,
                            },
                            {
                              value: "Android Application",
                              label: "Android Application",
                              price: 28.81,
                            },
                            {
                              value: "Web Application",
                              label: "Web Application",
                              price: 25.21,
                            },
                            {
                              value: "Simulation",
                              label: "Simulation",
                              price: 23.41,
                            },
                            {
                              value: "Data Visualization",
                              label: "Data Visualization",
                              price: 25.21,
                            },
                            {
                              value: "Data Modeling",
                              label: "Data Modeling",
                              price: 27.01,
                            },
                            {
                              value: "Database / SQL",
                              label: "Database / SQL",
                              price: 23.41,
                            },
                            {
                              value: "Animation",
                              label: "Animation",
                              price: 21.61,
                            },
                          ],
                        },
                        {
                          label: "Engineering",
                          options: [
                            {
                              value: "Mechanical Engineering",
                              label: "Mechanical Engineering",
                              price: 30.61,
                            },
                            {
                              value: "Oil & Gas Engineering",
                              label: "Oil & Gas Engineering",
                              price: 32.41,
                            },
                            {
                              value: "IT/ Software Engineering",
                              label: "IT/ Software Engineering",
                              price: 27.01,
                            },
                            {
                              value: "Electrical Engineering",
                              label: "Electrical Engineering",
                              price: 28.81,
                            },
                            {
                              value: "Electronics Engineering",
                              label: "Electronics Engineering",
                              price: 30.61,
                            },
                            {
                              value: "Chemical Engineering",
                              label: "Chemical Engineering",
                              price: 32.41,
                            },
                            {
                              value: "Other Engineering",
                              label: "Other Engineering",
                              price: 25.21,
                            },
                            {
                              value: "Civil Engineering",
                              label: "Civil Engineering",
                              price: 30.61,
                            },
                          ],
                        },
                      ]}
                      value={selectedSubject}
                      onChange={(value) => setSelectedSubject(value)}
                      placeholder="Select Subject"
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#3CB14ACC",
                          primary50: "#3CB14A99",
                          primary25: "#3CB14A66",
                          primary: "#3CB14A ",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="home__jumbotron__overlay__content__right__card__content__row">
                  <div className="home__jumbotron__overlay__content__right__card__content__row__entry">
                    <ReactSelect
                      classNamePrefix={"react__select"}
                      options={[
                        { value: 1, label: "1 Page / 250 Words", price: 1.78 },
                        { value: 2, label: "2 Pages / 500 Words", price: 3.57 },
                        { value: 3, label: "3 Pages / 750 Words", price: 5.35 },
                        {
                          value: 4,
                          label: "4 Pages / 1000 Words",
                          price: 7.13,
                        },
                        {
                          value: 5,
                          label: "5 Pages / 1250 Words",
                          price: 8.91,
                        },
                        {
                          value: 6,
                          label: "6 Pages / 1500 Words",
                          price: 10.7,
                        },
                        {
                          value: 7,
                          label: "7 Pages / 1750 Words",
                          price: 12.48,
                        },
                        {
                          value: 8,
                          label: "8 Pages / 2000 Words",
                          price: 14.26,
                        },
                        {
                          value: 9,
                          label: "9 Pages / 2250 Words",
                          price: 16.04,
                        },
                        {
                          value: 10,
                          label: "10 Pages / 2500 Words",
                          price: 17.82,
                        },
                        {
                          value: 11,
                          label: "11 Pages / 2750 Words",
                          price: 19.61,
                        },
                        {
                          value: 12,
                          label: "12 Pages / 3000 Words",
                          price: 21.39,
                        },
                        {
                          value: 13,
                          label: "13 Pages / 3250 Words",
                          price: 23.17,
                        },
                        {
                          value: 14,
                          label: "14 Pages / 3500 Words",
                          price: 24.95,
                        },
                        {
                          value: 15,
                          label: "15 Pages / 3750 Words",
                          price: 26.73,
                        },
                        {
                          value: 16,
                          label: "16 Pages / 4000 Words",
                          price: 28.51,
                        },
                        {
                          value: 17,
                          label: "17 Pages / 4250 Words",
                          price: 30.3,
                        },
                        {
                          value: 18,
                          label: "18 Pages / 4500 Words",
                          price: 32.08,
                        },
                        {
                          value: 19,
                          label: "19 Pages / 4750 Words",
                          price: 33.86,
                        },
                        {
                          value: 20,
                          label: "20 Pages / 5000 Words",
                          price: 35.64,
                        },
                        {
                          value: 21,
                          label: "21 Pages / 5250 Words",
                          price: 37.42,
                        },
                        {
                          value: 22,
                          label: "22 Pages / 5500 Words",
                          price: 39.21,
                        },
                        {
                          value: 23,
                          label: "23 Pages / 5750 Words",
                          price: 40.99,
                        },
                        {
                          value: 24,
                          label: "24 Pages / 6000 Words",
                          price: 42.77,
                        },
                        {
                          value: 25,
                          label: "25 Pages / 6250 Words",
                          price: 44.55,
                        },
                        {
                          value: 26,
                          label: "26 Pages / 6500 Words",
                          price: 46.33,
                        },
                        {
                          value: 27,
                          label: "27 Pages / 6750 Words",
                          price: 48.11,
                        },
                        {
                          value: 28,
                          label: "28 Pages / 7000 Words",
                          price: 49.9,
                        },
                        {
                          value: 29,
                          label: "29 Pages / 7250 Words",
                          price: 51.68,
                        },
                        {
                          value: 30,
                          label: "30 Pages / 7500 Words",
                          price: 53.46,
                        },
                        {
                          value: 40,
                          label: "40 Pages / 10000 Words",
                          price: 71.28,
                        },
                        {
                          value: 50,
                          label: "50 Pages / 12500 Words",
                          price: 89.1,
                        },
                        {
                          value: 60,
                          label: "60 Pages / 15000 Words",
                          price: 106.92,
                        },
                        {
                          value: 70,
                          label: "70 Pages / 17500 Words",
                          price: 124.73,
                        },
                        {
                          value: 80,
                          label: "80 Pages / 20000 Words",
                          price: 142.55,
                        },
                        {
                          value: 90,
                          label: "90 Pages / 22500 Words",
                          price: 160.37,
                        },
                        {
                          value: 100,
                          label: "100 Pages / 25000 Words",
                          price: 178.19,
                        },
                        {
                          value: 110,
                          label: "110 Pages / 27500 Words",
                          price: 196.01,
                        },
                        {
                          value: 120,
                          label: "120 Pages / 30000 Words",
                          price: 213.82,
                        },
                        {
                          value: 130,
                          label: "130 Pages / 32500 Words",
                          price: 231.64,
                        },
                        {
                          value: 140,
                          label: "140 Pages / 35000 Words",
                          price: 249.46,
                        },
                        {
                          value: 150,
                          label: "150 Pages / 37500 Words",
                          price: 267.28,
                        },
                        {
                          value: 160,
                          label: "160 Pages / 40000 Words",
                          price: 285.1,
                        },
                        {
                          value: 180,
                          label: "180 Pages / 45000 Words",
                          price: 320.74,
                        },
                        {
                          value: 200,
                          label: "200 Pages / 50000 Words",
                          price: 356.37,
                        },
                        {
                          value: 250,
                          label: "250 Pages / 62500 Words",
                          price: 427.65,
                        },
                      ]}
                      value={selectedPages}
                      onChange={(value) => setSelectedPages(value)}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#3CB14ACC",
                          primary50: "#3CB14A99",
                          primary25: "#3CB14A66",
                          primary: "#3CB14A ",
                        },
                      })}
                    />
                  </div>
                  <div className="home__jumbotron__overlay__content__right__card__content__row__entry">
                    <ReactSelect
                      classNamePrefix={"react__select"}
                      options={[
                        { value: "4 hours", label: "4 hours", price: 3.61 },
                        { value: "8 hours", label: "8 hours", price: 2.88 },
                        { value: "16 hours", label: "16 hours", price: 2.52 },
                        { value: "1 Day", label: "1 Day", price: 2.16 },
                        { value: "2 Days", label: "2 Days", price: 1.8 },
                        { value: "3 Days", label: "3 Days", price: 1.44 },
                        { value: "4 Days", label: "4 Days", price: 1.26 },
                        { value: "5 Days", label: "5 Days", price: 1.08 },
                        { value: "6 Days", label: "6 Days", price: 1.01 },
                        { value: "7 Days", label: "7 Days", price: 0.9 },
                        { value: "8 Days", label: "8 Days", price: 0.87 },
                        { value: "9 Days", label: "9 Days", price: 0.83 },
                        { value: "10 Days", label: "10 Days", price: 0.79 },
                        { value: "15 Days", label: "15 Days", price: 0.72 },
                        { value: "20 Days", label: "20 Days", price: 0.65 },
                        { value: "25 Days", label: "25 Days", price: 0.58 },
                        { value: "30 Days", label: "30 Days", price: 0.54 },
                      ]}
                      value={selectedTimeframe}
                      onChange={(value) => setSelectedTimeframe(value)}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary75: "#3CB14ACC",
                          primary50: "#3CB14A99",
                          primary25: "#3CB14A66",
                          primary: "#3CB14A ",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
              <div className="home__jumbotron__overlay__content__right__card__footer">
                <hr
                  style={{
                    width: "100%",
                  }}
                />
                <div className="home__jumbotron__overlay__content__right__card__content__row">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "12px",
                      gap: ".3em",
                    }}
                    className="home__jumbotron__overlay__content__right__card__content__row__entry"
                  >
                    <span
                      style={{
                        backgroundColor: "#3cb149",
                        color: "white",
                        padding: ".5em",
                        borderRadius: "5px",
                      }}
                    >
                      25% Off
                    </span>
                    <span style={{ textDecoration: "line-through" }}>
                      ${price}
                    </span>
                  </div>
                  <div
                    style={{
                      fontWeight: 600,
                      fontSize: "18px",
                      textAlign: "right",
                    }}
                    className="home__jumbotron__overlay__content__right__card__content__row__entry"
                  >
                    ${finalPrice}
                  </div>
                </div>
                <Link
                  to="/order"
                  state={{
                    selectedService,
                    selectedSubject,
                    selectedPages,
                    selectedTimeframe,
                  }}
                  style={{
                    margin: 0,
                    flex: 1,
                    maxWidth: "100%",
                    display: "block",
                    textAlign: "center",
                  }}
                  className="header__content__nav__buttons__button header__content__nav__buttons__button__secondary"
                >
                  Order Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
