import React, { useState } from "react";
import { ProductsBecomeResourceInput } from "../components/ProductsBecomeResourceInput";
import ReactSelect from "react-select";
import { useLocation } from "react-router-dom";

export function ProductsBecomeResource() {
  const { state } = useLocation();
  const [selectedService, setSelectedService] = useState(
    state.selectedService || null
  );
  const [selectedSubject, setSelectedSubject] = useState(
    state.selectedSubject || null
  );
  const [selectedPages, setSelectedPages] = useState(
    state.selectedPages || {
      value: 5,
      label: "5 Pages / 1250 Words",
      price: 8.91,
    }
  );
  const [selectedTimeframe, setSelectedTimeframe] = useState(
    state.selectedTimeframe || {
      value: "1 Day",
      label: "1 Day",
      price: 2.16,
    }
  );
  const [uploadedFile, setUploadedFile] = useState(null);

  console.log("state", state);

  const price = parseFloat(
    (selectedService?.price ?? 0) +
      (selectedSubject?.price ?? 0) +
      (selectedPages?.price ?? 0) +
      (selectedTimeframe?.price ?? 0)
  ).toFixed(2);

  const discountPercentage = 25;

  const discountAmount = (price * discountPercentage) / 100;

  const finalPrice = parseFloat(price - discountAmount).toFixed(2);

  console.table([price, finalPrice]);

  return (
    <div className="products__become__resource">
      <div className="products__become__resource__form">
        <div className="products__become__resource__form__heading">
          <span>Order</span>
        </div>
        <div className="products__become__resource__form__content">
          <div className="home__jumbotron__overlay__content__right__card__content__row">
            <div className="home__jumbotron__overlay__content__right__card__content__row__entry">
              <ReactSelect
                classNamePrefix={"order__select"}
                options={[
                  {
                    value: "Assignment",
                    label: "Assignment",
                    price: 10.79, // 3000 PKR / 277.856
                  },
                  {
                    value: "Coursework",
                    label: "Coursework",
                    price: 14.39, // 4000 PKR / 277.856
                  },
                  {
                    value: "Homework",
                    label: "Homework",
                    price: 8.99, // 2500 PKR / 277.856
                  },
                  {
                    value: "Term Paper",
                    label: "Term Paper",
                    price: 17.99, // 5000 PKR / 277.856
                  },
                  {
                    value: "Research Paper",
                    label: "Research Paper",
                    price: 21.59, // 6000 PKR / 277.856
                  },
                  {
                    value: "Research Proposal",
                    label: "Research Proposal",
                    price: 16.19, // 4500 PKR / 277.856
                  },
                  {
                    value: "Personal Statement",
                    label: "Personal Statement",
                    price: 12.59, // 3500 PKR / 277.856
                  },
                  {
                    value: "Case Study",
                    label: "Case Study",
                    price: 19.79, // 5500 PKR / 277.856
                  },
                  {
                    value: "Report Writing",
                    label: "Report Writing",
                    price: 14.39, // 4000 PKR / 277.856
                  },
                  {
                    value: "Dissertation",
                    label: "Dissertation",
                    price: 35.99, // 10000 PKR / 277.856
                  },
                  {
                    value: "PHD Thesis",
                    label: "PHD Thesis",
                    price: 53.99, // 15000 PKR / 277.856
                  },
                  {
                    value: "Dissertation Proposal",
                    label: "Dissertation Proposal",
                    price: 25.19, // 7000 PKR / 277.856
                  },
                  {
                    value: "Editing | Proofreading",
                    label: "Editing | Proofreading",
                    price: 10.79, // 3000 PKR / 277.856
                  },
                  {
                    value: "Paraphrasing",
                    label: "Paraphrasing",
                    price: 7.19, // 2000 PKR / 277.856
                  },
                  {
                    value: "Business Plan",
                    label: "Business Plan",
                    price: 28.79, // 8000 PKR / 277.856
                  },
                  {
                    value: "Essay",
                    label: "Essay",
                    price: 12.59, // 3500 PKR / 277.856
                  },
                  {
                    value: "Resume / CV Services",
                    label: "Resume / CV Services",
                    price: 8.99, // 2500 PKR / 277.856
                  },
                  {
                    value: "PowerPoint Presentation",
                    label: "PowerPoint Presentation",
                    price: 10.79, // 3000 PKR / 277.856
                  },
                  {
                    value: "Plagiarism Report",
                    label: "Plagiarism Report",
                    price: 5.39, // 1500 PKR / 277.856
                  },
                  {
                    value: "Poster",
                    label: "Poster",
                    price: 7.19, // 2000 PKR / 277.856
                  },
                  {
                    value: "Leaflet",
                    label: "Leaflet",
                    price: 5.39, // 1500 PKR / 277.856
                  },
                  {
                    value: "Online Exam | Question based test Papers",
                    label: "Online Exam | Question based test Papers",
                    price: 17.99, // 5000 PKR / 277.856
                  },
                  {
                    value: "Multiple Choice Questions",
                    label: "Multiple Choice Questions",
                    price: 10.79, // 3000 PKR / 277.856
                  },
                  {
                    value: "Summary Writing",
                    label: "Summary Writing",
                    price: 8.99, // 2500 PKR / 277.856
                  },
                  {
                    value: "Portfolio",
                    label: "Portfolio",
                    price: 21.59, // 6000 PKR / 277.856
                  },
                ]}
                value={selectedService}
                onChange={(value) => setSelectedService(value)}
                placeholder="Select Services"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  control: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  input: (provided) => ({
                    ...provided,
                    flex: "1 1 auto", // Prevent shrinking of the input
                    width: "100% !important", // Force width to stay 100%
                    minWidth: "100% !important",
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: "100%",
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary75: "#3CB14ACC",
                    primary50: "#3CB14A99",
                    primary25: "#3CB14A66",
                    primary: "#3CB14A ",
                  },
                })}
              />
            </div>
          </div>

          <div className="home__jumbotron__overlay__content__right__card__content__row">
            <div className="home__jumbotron__overlay__content__right__card__content__row__entry">
              <ReactSelect
                classNamePrefix={"order__select"}
                options={[
                  {
                    label: "Management",
                    options: [
                      {
                        value: "Economics (theory)",
                        label: "Economics (theory)",
                        price: 14.4,
                      },
                      {
                        value: "Leisure Management",
                        label: "Leisure Management",
                        price: 12.6,
                      },
                      {
                        value: "Human Resource",
                        label: "Human Resource",
                        price: 16.2,
                      },
                      {
                        value: "Housing",
                        label: "Housing",
                        price: 10.8,
                      },
                      {
                        value: "Operations",
                        label: "Operations",
                        price: 18.01,
                      },
                      {
                        value: "Fashion",
                        label: "Fashion",
                        price: 14.4,
                      },
                      {
                        value: "Estate Management",
                        label: "Estate Management",
                        price: 19.81,
                      },
                      {
                        value: "Human Resource Management",
                        label: "Human Resource Management",
                        price: 18.01,
                      },
                      {
                        value: "Reflective",
                        label: "Reflective",
                        price: 12.6,
                      },
                      {
                        value: "Other Management",
                        label: "Other Management",
                        price: 10.8,
                      },
                      {
                        value: "Marketing",
                        label: "Marketing",
                        price: 21.61,
                      },
                      {
                        value: "Leadership",
                        label: "Leadership",
                        price: 18.01,
                      },
                      {
                        value: "Research Methodology",
                        label: "Research Methodology",
                        price: 25.21,
                      },
                      {
                        value: "Communication",
                        label: "Communication",
                        price: 10.8,
                      },
                      {
                        value: "Human Rights",
                        label: "Human Rights",
                        price: 14.4,
                      },
                      {
                        value: "International Relations",
                        label: "International Relations",
                        price: 19.81,
                      },
                      {
                        value: "Business",
                        label: "Business",
                        price: 18.01,
                      },
                      {
                        value: "International Studies",
                        label: "International Studies",
                        price: 21.61,
                      },
                      {
                        value: "Information Systems",
                        label: "Information Systems",
                        price: 19.81,
                      },
                      {
                        value: "Project Management",
                        label: "Project Management",
                        price: 25.21,
                      },
                      {
                        value: "Economics (calculative)",
                        label: "Economics (calculative)",
                        price: 16.2,
                      },
                      {
                        value: "Small Business Enterprise",
                        label: "Small Business Enterprise",
                        price: 21.61,
                      },
                    ],
                  },
                  {
                    label: "Finance",
                    options: [
                      {
                        value: "Statistics",
                        label: "Statistics",
                        price: 18.01,
                      },
                      {
                        value: "Financial Management",
                        label: "Financial Management",
                        price: 25.21,
                      },
                      {
                        value: "Accounts",
                        label: "Accounts",
                        price: 14.4,
                      },
                      {
                        value: "Commerce",
                        label: "Commerce",
                        price: 16.2,
                      },
                      {
                        value: "Eviews",
                        label: "Eviews",
                        price: 19.81,
                      },
                      {
                        value: "SAS",
                        label: "SAS",
                        price: 21.61,
                      },
                      {
                        value: "NVIVO",
                        label: "NVIVO",
                        price: 25.21,
                      },
                      {
                        value: "SPSS",
                        label: "SPSS",
                        price: 18.01,
                      },
                    ],
                  },
                  {
                    label: "Tourism",
                    options: [
                      {
                        value: "Travel & Tourism",
                        label: "Travel & Tourism",
                        price: 14.4,
                      },
                      {
                        value: "Food",
                        label: "Food",
                        price: 12.6,
                      },
                      {
                        value: "Tourism",
                        label: "Tourism",
                        price: 16.2,
                      },
                      {
                        value: "Hospitality",
                        label: "Hospitality",
                        price: 18.01,
                      },
                    ],
                  },
                  {
                    label: "Law",
                    options: [
                      {
                        value: "Law",
                        label: "Law",
                        price: 18.01,
                      },
                      {
                        value: "Legal Aspects",
                        label: "Legal Aspects",
                        price: 21.61,
                      },
                      {
                        value: "Legal",
                        label: "Legal",
                        price: 19.81,
                      },
                      {
                        value: "Business Law",
                        label: "Business Law",
                        price: 21.61,
                      },
                    ],
                  },
                  {
                    label: "Health",
                    options: [
                      {
                        value: "Health",
                        label: "Health",
                        price: 16.2,
                      },
                      {
                        value: "Healthcare",
                        label: "Healthcare",
                        price: 19.81,
                      },
                      {
                        value: "Health & Social Care",
                        label: "Health & Social Care",
                        price: 21.61,
                      },
                      {
                        value: "Pharmacy",
                        label: "Pharmacy",
                        price: 18.01,
                      },
                      {
                        value: "Medical",
                        label: "Medical",
                        price: 23.41,
                      },
                      {
                        value: "Childcare",
                        label: "Childcare",
                        price: 14.4,
                      },
                      {
                        value: "Nursing",
                        label: "Nursing",
                        price: 21.61,
                      },
                      {
                        value: "Physiology",
                        label: "Physiology",
                        price: 18.01,
                      },
                      {
                        value: "Physical Education",
                        label: "Physical Education",
                        price: 16.2,
                      },
                      {
                        value: "Biotechnology",
                        label: "Biotechnology",
                        price: 25.21,
                      },
                      {
                        value: "Lab-Report",
                        label: "Lab-Report",
                        price: 14.4,
                      },
                    ],
                  },
                  {
                    label: "Arts & Humanities",
                    options: [
                      {
                        value: "Social Policy",
                        label: "Social Policy",
                        price: 18.01,
                      },
                      {
                        value: "Social Work",
                        label: "Social Work",
                        price: 19.81,
                      },
                      {
                        value: "Sociology",
                        label: "Sociology",
                        price: 16.2,
                      },
                      {
                        value: "Sports",
                        label: "Sports",
                        price: 14.4,
                      },
                      {
                        value: "Military",
                        label: "Military",
                        price: 21.61,
                      },
                      {
                        value: "Theology",
                        label: "Theology",
                        price: 18.01,
                      },
                      {
                        value: "Psychology",
                        label: "Psychology",
                        price: 19.81,
                      },
                      {
                        value: "Property",
                        label: "Property",
                        price: 18.01,
                      },
                      {
                        value: "Arts",
                        label: "Arts",
                        price: 16.2,
                      },
                      {
                        value: "Criminology",
                        label: "Criminology",
                        price: 18.01,
                      },
                      {
                        value: "Environmental Studies",
                        label: "Environmental Studies",
                        price: 19.81,
                      },
                      {
                        value: "General Studies",
                        label: "General Studies",
                        price: 12.6,
                      },
                      {
                        value: "Geography",
                        label: "Geography",
                        price: 16.2,
                      },
                      {
                        value: "History",
                        label: "History",
                        price: 14.4,
                      },
                      {
                        value: "Anthropology",
                        label: "Anthropology",
                        price: 18.01,
                      },
                      {
                        value: "Religion",
                        label: "Religion",
                        price: 14.4,
                      },
                      {
                        value: "Politics",
                        label: "Politics",
                        price: 18.01,
                      },
                      {
                        value: "Philosophy",
                        label: "Philosophy",
                        price: 16.2,
                      },
                      {
                        value: "Geology",
                        label: "Geology",
                        price: 21.61,
                      },
                    ],
                  },
                  {
                    label: "Education",
                    options: [
                      {
                        value: "Translation",
                        label: "Translation",
                        price: 14.4,
                      },
                      {
                        value: "Education",
                        label: "Education",
                        price: 12.6,
                      },
                      {
                        value: "Teaching",
                        label: "Teaching",
                        price: 14.4,
                      },
                    ],
                  },
                  {
                    label: "Languages",
                    options: [
                      {
                        value: "Linguistics",
                        label: "Linguistics",
                        price: 16.2,
                      },
                      {
                        value: "English Literature",
                        label: "English Literature",
                        price: 18.01,
                      },
                      {
                        value: "English Language",
                        label: "English Language",
                        price: 14.4,
                      },
                      {
                        value: "German",
                        label: "German",
                        price: 19.81,
                      },
                      {
                        value: "Languages",
                        label: "Languages",
                        price: 16.2,
                      },
                      {
                        value: "Portuguese",
                        label: "Portuguese",
                        price: 18.01,
                      },
                      {
                        value: "Spanish",
                        label: "Spanish",
                        price: 18.01,
                      },
                    ],
                  },
                  {
                    label: "Culture & Drama",
                    options: [
                      {
                        value: "Theatre",
                        label: "Theatre",
                        price: 16.2,
                      },
                      {
                        value: "Journalism",
                        label: "Journalism",
                        price: 18.01,
                      },
                      {
                        value: "Film Studies",
                        label: "Film Studies",
                        price: 19.81,
                      },
                      {
                        value: "Photography",
                        label: "Photography",
                        price: 16.2,
                      },
                      {
                        value: "Film Review",
                        label: "Film Review",
                        price: 14.4,
                      },
                      {
                        value: "Drama",
                        label: "Drama",
                        price: 18.01,
                      },
                      {
                        value: "Cultural Studies",
                        label: "Cultural Studies",
                        price: 19.81,
                      },
                      {
                        value: "Media",
                        label: "Media",
                        price: 21.61,
                      },
                      {
                        value: "Music",
                        label: "Music",
                        price: 18.01,
                      },
                    ],
                  },
                  {
                    label: "Science",
                    options: [
                      {
                        value: "Mechanics",
                        label: "Mechanics",
                        price: 21.61,
                      },
                      {
                        value: "Chemistry",
                        label: "Chemistry",
                        price: 19.81,
                      },
                      {
                        value: "Other Science",
                        label: "Other Science",
                        price: 18.01,
                      },
                      {
                        value: "Physics",
                        label: "Physics",
                        price: 21.61,
                      },
                      {
                        value: "Mathematics",
                        label: "Mathematics",
                        price: 18.01,
                      },
                      {
                        value: "Information Technology",
                        label: "Information Technology",
                        price: 19.81,
                      },
                      {
                        value: "Computer Science",
                        label: "Computer Science",
                        price: 21.61,
                      },
                      {
                        value: "Biology",
                        label: "Biology",
                        price: 19.81,
                      },
                      {
                        value: "Architecture",
                        label: "Architecture",
                        price: 23.41,
                      },
                      {
                        value: "Construction",
                        label: "Construction",
                        price: 21.61,
                      },
                    ],
                  },
                  {
                    label: "Programming",
                    options: [
                      {
                        value: "IT-Networking",
                        label: "IT-Networking",
                        price: 25.21,
                      },
                      {
                        value: "Operating System",
                        label: "Operating System",
                        price: 21.61,
                      },
                      {
                        value: "Data Mining",
                        label: "Data Mining",
                        price: 28.81,
                      },
                      {
                        value: "MATLAB",
                        label: "MATLAB",
                        price: 25.21,
                      },
                      {
                        value: "Linux",
                        label: "Linux",
                        price: 19.81,
                      },
                      {
                        value: "Python",
                        label: "Python",
                        price: 21.61,
                      },
                      {
                        value: "Other Programming",
                        label: "Other Programming",
                        price: 18.01,
                      },
                      {
                        value: "iOS Application",
                        label: "iOS Application",
                        price: 28.81,
                      },
                      {
                        value: "Android Application",
                        label: "Android Application",
                        price: 28.81,
                      },
                      {
                        value: "Web Application",
                        label: "Web Application",
                        price: 25.21,
                      },
                      {
                        value: "Simulation",
                        label: "Simulation",
                        price: 23.41,
                      },
                      {
                        value: "Data Visualization",
                        label: "Data Visualization",
                        price: 25.21,
                      },
                      {
                        value: "Data Modeling",
                        label: "Data Modeling",
                        price: 27.01,
                      },
                      {
                        value: "Database / SQL",
                        label: "Database / SQL",
                        price: 23.41,
                      },
                      {
                        value: "Animation",
                        label: "Animation",
                        price: 21.61,
                      },
                    ],
                  },
                  {
                    label: "Engineering",
                    options: [
                      {
                        value: "Mechanical Engineering",
                        label: "Mechanical Engineering",
                        price: 30.61,
                      },
                      {
                        value: "Oil & Gas Engineering",
                        label: "Oil & Gas Engineering",
                        price: 32.41,
                      },
                      {
                        value: "IT/ Software Engineering",
                        label: "IT/ Software Engineering",
                        price: 27.01,
                      },
                      {
                        value: "Electrical Engineering",
                        label: "Electrical Engineering",
                        price: 28.81,
                      },
                      {
                        value: "Electronics Engineering",
                        label: "Electronics Engineering",
                        price: 30.61,
                      },
                      {
                        value: "Chemical Engineering",
                        label: "Chemical Engineering",
                        price: 32.41,
                      },
                      {
                        value: "Other Engineering",
                        label: "Other Engineering",
                        price: 25.21,
                      },
                      {
                        value: "Civil Engineering",
                        label: "Civil Engineering",
                        price: 30.61,
                      },
                    ],
                  },
                ]}
                value={selectedSubject}
                onChange={(value) => setSelectedSubject(value)}
                placeholder="Select Subject"
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary75: "#3CB14ACC",
                    primary50: "#3CB14A99",
                    primary25: "#3CB14A66",
                    primary: "#3CB14A ",
                  },
                })}
              />
            </div>
            <div className="home__jumbotron__overlay__content__right__card__content__row__entry">
              <ReactSelect
                classNamePrefix={"order__select"}
                options={[
                  { value: 1, label: "1 Page / 250 Words", price: 1.78 },
                  { value: 2, label: "2 Pages / 500 Words", price: 3.57 },
                  { value: 3, label: "3 Pages / 750 Words", price: 5.35 },
                  {
                    value: 4,
                    label: "4 Pages / 1000 Words",
                    price: 7.13,
                  },
                  {
                    value: 5,
                    label: "5 Pages / 1250 Words",
                    price: 8.91,
                  },
                  {
                    value: 6,
                    label: "6 Pages / 1500 Words",
                    price: 10.7,
                  },
                  {
                    value: 7,
                    label: "7 Pages / 1750 Words",
                    price: 12.48,
                  },
                  {
                    value: 8,
                    label: "8 Pages / 2000 Words",
                    price: 14.26,
                  },
                  {
                    value: 9,
                    label: "9 Pages / 2250 Words",
                    price: 16.04,
                  },
                  {
                    value: 10,
                    label: "10 Pages / 2500 Words",
                    price: 17.82,
                  },
                  {
                    value: 11,
                    label: "11 Pages / 2750 Words",
                    price: 19.61,
                  },
                  {
                    value: 12,
                    label: "12 Pages / 3000 Words",
                    price: 21.39,
                  },
                  {
                    value: 13,
                    label: "13 Pages / 3250 Words",
                    price: 23.17,
                  },
                  {
                    value: 14,
                    label: "14 Pages / 3500 Words",
                    price: 24.95,
                  },
                  {
                    value: 15,
                    label: "15 Pages / 3750 Words",
                    price: 26.73,
                  },
                  {
                    value: 16,
                    label: "16 Pages / 4000 Words",
                    price: 28.51,
                  },
                  {
                    value: 17,
                    label: "17 Pages / 4250 Words",
                    price: 30.3,
                  },
                  {
                    value: 18,
                    label: "18 Pages / 4500 Words",
                    price: 32.08,
                  },
                  {
                    value: 19,
                    label: "19 Pages / 4750 Words",
                    price: 33.86,
                  },
                  {
                    value: 20,
                    label: "20 Pages / 5000 Words",
                    price: 35.64,
                  },
                  {
                    value: 21,
                    label: "21 Pages / 5250 Words",
                    price: 37.42,
                  },
                  {
                    value: 22,
                    label: "22 Pages / 5500 Words",
                    price: 39.21,
                  },
                  {
                    value: 23,
                    label: "23 Pages / 5750 Words",
                    price: 40.99,
                  },
                  {
                    value: 24,
                    label: "24 Pages / 6000 Words",
                    price: 42.77,
                  },
                  {
                    value: 25,
                    label: "25 Pages / 6250 Words",
                    price: 44.55,
                  },
                  {
                    value: 26,
                    label: "26 Pages / 6500 Words",
                    price: 46.33,
                  },
                  {
                    value: 27,
                    label: "27 Pages / 6750 Words",
                    price: 48.11,
                  },
                  {
                    value: 28,
                    label: "28 Pages / 7000 Words",
                    price: 49.9,
                  },
                  {
                    value: 29,
                    label: "29 Pages / 7250 Words",
                    price: 51.68,
                  },
                  {
                    value: 30,
                    label: "30 Pages / 7500 Words",
                    price: 53.46,
                  },
                  {
                    value: 40,
                    label: "40 Pages / 10000 Words",
                    price: 71.28,
                  },
                  {
                    value: 50,
                    label: "50 Pages / 12500 Words",
                    price: 89.1,
                  },
                  {
                    value: 60,
                    label: "60 Pages / 15000 Words",
                    price: 106.92,
                  },
                  {
                    value: 70,
                    label: "70 Pages / 17500 Words",
                    price: 124.73,
                  },
                  {
                    value: 80,
                    label: "80 Pages / 20000 Words",
                    price: 142.55,
                  },
                  {
                    value: 90,
                    label: "90 Pages / 22500 Words",
                    price: 160.37,
                  },
                  {
                    value: 100,
                    label: "100 Pages / 25000 Words",
                    price: 178.19,
                  },
                  {
                    value: 110,
                    label: "110 Pages / 27500 Words",
                    price: 196.01,
                  },
                  {
                    value: 120,
                    label: "120 Pages / 30000 Words",
                    price: 213.82,
                  },
                  {
                    value: 130,
                    label: "130 Pages / 32500 Words",
                    price: 231.64,
                  },
                  {
                    value: 140,
                    label: "140 Pages / 35000 Words",
                    price: 249.46,
                  },
                  {
                    value: 150,
                    label: "150 Pages / 37500 Words",
                    price: 267.28,
                  },
                  {
                    value: 160,
                    label: "160 Pages / 40000 Words",
                    price: 285.1,
                  },
                  {
                    value: 180,
                    label: "180 Pages / 45000 Words",
                    price: 320.74,
                  },
                  {
                    value: 200,
                    label: "200 Pages / 50000 Words",
                    price: 356.37,
                  },
                  {
                    value: 250,
                    label: "250 Pages / 62500 Words",
                    price: 427.65,
                  },
                ]}
                value={selectedPages}
                onChange={(value) => setSelectedPages(value)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary75: "#3CB14ACC",
                    primary50: "#3CB14A99",
                    primary25: "#3CB14A66",
                    primary: "#3CB14A ",
                  },
                })}
              />
            </div>
            <div className="home__jumbotron__overlay__content__right__card__content__row__entry">
              <ReactSelect
                classNamePrefix={"order__select"}
                options={[
                  { value: "4 hours", label: "4 hours", price: 3.61 },
                  { value: "8 hours", label: "8 hours", price: 2.88 },
                  { value: "16 hours", label: "16 hours", price: 2.52 },
                  { value: "1 Day", label: "1 Day", price: 2.16 },
                  { value: "2 Days", label: "2 Days", price: 1.8 },
                  { value: "3 Days", label: "3 Days", price: 1.44 },
                  { value: "4 Days", label: "4 Days", price: 1.26 },
                  { value: "5 Days", label: "5 Days", price: 1.08 },
                  { value: "6 Days", label: "6 Days", price: 1.01 },
                  { value: "7 Days", label: "7 Days", price: 0.9 },
                  { value: "8 Days", label: "8 Days", price: 0.87 },
                  { value: "9 Days", label: "9 Days", price: 0.83 },
                  { value: "10 Days", label: "10 Days", price: 0.79 },
                  { value: "15 Days", label: "15 Days", price: 0.72 },
                  { value: "20 Days", label: "20 Days", price: 0.65 },
                  { value: "25 Days", label: "25 Days", price: 0.58 },
                  { value: "30 Days", label: "30 Days", price: 0.54 },
                ]}
                value={selectedTimeframe}
                onChange={(value) => setSelectedTimeframe(value)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary75: "#3CB14ACC",
                    primary50: "#3CB14A99",
                    primary25: "#3CB14A66",
                    primary: "#3CB14A ",
                  },
                })}
              />
            </div>
          </div>
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-captions"
              >
                <rect width="18" height="14" x="3" y="5" rx="2" ry="2" />
                <path d="M7 15h4M15 15h2M7 11h2M13 11h4" />
              </svg>
            }
            type="text"
            placeholder="Enter Topic"
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="lucide lucide-captions"
              >
                <rect width="18" height="14" x="3" y="5" rx="2" ry="2" />
                <path d="M7 15h4M15 15h2M7 11h2M13 11h4" />
              </svg>
            }
            type="text"
            placeholder="Specify Your Requirements Here"
          />
          {/*
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.6"
                height="13.92"
                viewBox="0 0 14.6 13.92"
              >
                <path
                  id="Icon_material-work"
                  data-name="Icon material-work"
                  d="M15.24,5.72H12.52V4.36A1.355,1.355,0,0,0,11.16,3H8.44A1.355,1.355,0,0,0,7.08,4.36V5.72H4.36A1.35,1.35,0,0,0,3.007,7.08L3,14.56a1.355,1.355,0,0,0,1.36,1.36H15.24a1.355,1.355,0,0,0,1.36-1.36V7.08A1.355,1.355,0,0,0,15.24,5.72Z"
                  transform="translate(-2.5 -2.5)"
                  fill="none"
                  stroke="#000"
                  strokeWidth="1"
                />
              </svg>
            }
            type="text"
            placeholder="Profession"
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.18"
                height="14.744"
                viewBox="0 0 18.18 14.744"
              >
                <path
                  id="Path_9446"
                  data-name="Path 9446"
                  d="M4.718,6H18.462A1.723,1.723,0,0,1,20.18,7.718V18.026a1.723,1.723,0,0,1-1.718,1.718H4.718A1.723,1.723,0,0,1,3,18.026V7.718A1.723,1.723,0,0,1,4.718,6Z"
                  transform="translate(-2.5 -5.5)"
                  fill="none"
                  stroke="#0d0d0d"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1"
                />
              </svg>
            }
            type="email"
            placeholder="Email"
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12.97"
                height="12.97"
                viewBox="0 0 12.97 12.97"
              >
                <path
                  id="Icon_ionic-ios-add"
                  data-name="Icon ionic-ios-add"
                  d="M21.127,14.642h-4.87V9.772a.807.807,0,0,0-1.615,0v4.87H9.772a.807.807,0,0,0,0,1.615h4.87v4.87a.807.807,0,1,0,1.615,0v-4.87h4.87a.807.807,0,1,0,0-1.615Z"
                  transform="translate(-8.965 -8.965)"
                />
              </svg>
            }
            type="text"
            placeholder="Years of Experience"
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18.125"
                height="18.124"
                viewBox="0 0 18.125 18.124"
              >
                <path
                  id="Icon_ionic-ios-call"
                  data-name="Icon ionic-ios-call"
                  d="M21.174,17.954a14.43,14.43,0,0,0-3.014-2.017c-.9-.434-1.234-.425-1.874.036-.532.385-.877.742-1.489.608A8.884,8.884,0,0,1,11.8,14.367,8.82,8.82,0,0,1,9.59,11.375c-.13-.617.228-.957.608-1.489.461-.64.474-.97.036-1.874A14.145,14.145,0,0,0,8.217,5c-.657-.657-.805-.514-1.167-.385a6.642,6.642,0,0,0-1.069.568A3.224,3.224,0,0,0,4.7,6.536c-.255.55-.55,1.574.953,4.249a23.707,23.707,0,0,0,4.168,5.559h0l0,0,0,0h0a23.8,23.8,0,0,0,5.559,4.168c2.674,1.5,3.7,1.208,4.249.953A3.169,3.169,0,0,0,20.99,20.19a6.642,6.642,0,0,0,.568-1.069C21.688,18.759,21.836,18.611,21.174,17.954Z"
                  transform="translate(-4.014 -4.033)"
                  fill="none"
                  stroke="#0d0d0d"
                  strokeWidth="1"
                />
              </svg>
            }
            type="tel"
            placeholder="Number"
          />{" "}
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.861"
                height="16.401"
                viewBox="0 0 14.861 16.401"
              >
                <g
                  id="Icon_feather-lock"
                  data-name="Icon feather-lock"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Path_9444"
                    data-name="Path 9444"
                    d="M6.04,16.5h10.78a1.54,1.54,0,0,1,1.54,1.54v5.39a1.54,1.54,0,0,1-1.54,1.54H6.04A1.54,1.54,0,0,1,4.5,23.43V18.04A1.54,1.54,0,0,1,6.04,16.5Z"
                    transform="translate(-4.5 -9.57)"
                    fill="none"
                    stroke="#0d0d0d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_9445"
                    data-name="Path 9445"
                    d="M10.5,9.93V6.85a3.85,3.85,0,0,1,7.7,0V9.93"
                    transform="translate(-7.42 -3)"
                    fill="none"
                    stroke="#0d0d0d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            }
            type="text"
            placeholder="Password"
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.778"
                height="16.5"
                viewBox="0 0 14.778 16.5"
              >
                <g
                  id="Icon_feather-user"
                  data-name="Icon feather-user"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Path_9706"
                    data-name="Path 9706"
                    d="M19.778,27.667V25.944A3.444,3.444,0,0,0,16.333,22.5H9.444A3.444,3.444,0,0,0,6,25.944v1.722"
                    transform="translate(-6 -12.167)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_9707"
                    data-name="Path 9707"
                    d="M18.889,7.944A3.444,3.444,0,1,1,15.444,4.5,3.444,3.444,0,0,1,18.889,7.944Z"
                    transform="translate(-8.556 -4.5)"
                    fill="none"
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            }
            type="text"
            placeholder="CNIC"
          />
          <ProductsBecomeResourceInput
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14.861"
                height="16.401"
                viewBox="0 0 14.861 16.401"
              >
                <g
                  id="Icon_feather-lock"
                  data-name="Icon feather-lock"
                  transform="translate(0.5 0.5)"
                >
                  <path
                    id="Path_9444"
                    data-name="Path 9444"
                    d="M6.04,16.5h10.78a1.54,1.54,0,0,1,1.54,1.54v5.39a1.54,1.54,0,0,1-1.54,1.54H6.04A1.54,1.54,0,0,1,4.5,23.43V18.04A1.54,1.54,0,0,1,6.04,16.5Z"
                    transform="translate(-4.5 -9.57)"
                    fill="none"
                    stroke="#0d0d0d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                  <path
                    id="Path_9445"
                    data-name="Path 9445"
                    d="M10.5,9.93V6.85a3.85,3.85,0,0,1,7.7,0V9.93"
                    transform="translate(-7.42 -3)"
                    fill="none"
                    stroke="#0d0d0d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                  />
                </g>
              </svg>
            }
            type="text"
            placeholder="Confirm Password"
          /> */}
          <div className="products__become__resource__form__content__upload">
            <input
              type="file"
              onChange={(e) => setUploadedFile(e.target.files[0])}
              className="products__become__resource__form__content__upload__input"
            />
            <div className="products__become__resource__form__content__upload__content">
              {uploadedFile ? (
                uploadedFile.name
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.97"
                    height="12.97"
                    viewBox="0 0 12.97 12.97"
                  >
                    <path
                      id="Icon_ionic-ios-add"
                      data-name="Icon ionic-ios-add"
                      d="M21.127,14.642h-4.87V9.772a.807.807,0,0,0-1.615,0v4.87H9.772a.807.807,0,0,0,0,1.615h4.87v4.87a.807.807,0,1,0,1.615,0v-4.87h4.87a.807.807,0,1,0,0-1.615Z"
                      transform="translate(-8.965 -8.965)"
                    />
                  </svg>
                  Upload file
                </>
              )}
            </div>
          </div>
        </div>
        <div className="products__become__resource__form__text">
          <input type="checkbox" />I agree to{" "}
          <span>
            <a href="#">Terms and conditions</a> & <a href="#">Privacy</a>
          </span>
        </div>
      </div>
      <div className="products__become__resource__sidebar">
        <h3>Order Summary</h3>
        <hr
          style={{
            margin: "0em",
            width: "100%",
          }}
        />
        <div>{selectedService?.label || "Essay"}</div>
        <div>{selectedSubject?.label || "Communication"}</div>
        <div>{selectedPages?.label}</div>
        <div>{selectedTimeframe?.label}</div>
        <div className="products__become__resource__sidebar__container">
          <div className="products__become__resource__sidebar__row">
            <span>Basic Price</span>
            <span>${price}</span>
          </div>

          <div className="products__become__resource__sidebar__row">
            <span>Plagiarism Report</span>
            <span>FREE</span>
          </div>
          <div className="products__become__resource__sidebar__row">
            <span>Discount </span>
            <span>${discountAmount}</span>
          </div>
        </div>
        <div className="products__become__resource__sidebar__row products__become__resource__sidebar__row__total">
          <span>Total Amount</span>
          <span>${finalPrice}</span>
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <button
            style={{
              width: "100%",
            }}
            className="products__become__resource__form__button"
          >
            Order
          </button>
        </div>
      </div>
    </div>
  );
}
