export const readymaidConceptsData = [
  "It’s not just about creating a platform for assignments, but about reshaping the way academic support is offered.",
  "It’s about empowering students and professionals who need guidance in their educational journey.",
  "It’s about providing access to academic expertise to those who might not otherwise have it.",
  "It’s about ensuring academic success for those struggling to meet deadlines or maintain academic performance.",
  "It’s not about profiting from students, but about giving them the tools to succeed.",
  "It’s about reaching out to students in need, not waiting for them to come to us.",
  "It’s about creating a platform where anyone can find the academic support they need.",
  "It’s about fostering an environment where students are valued for their efforts and potential, not just their grades.",
  "It’s not about just finding answers, but helping students develop the right skills for academic success.",
];
