import React from "react";
import service from "../assets/serviceend.png";

export function AboutBestServices() {
  return (
    <div className="Best__services__coustomer__container">
      <div className="Best__services__coustomer__container__main">
        <div className="Best__services__coustomer__container__heading">
          We Provide
        </div>
        <div className="Best__services__coustomer__container__heading__two">
          <span>Best Quality Services</span>
        </div>
        <div className="Best__services__coustomer__container__para">
          ReadyAssignment is a one-stop platform connecting students and
          professionals with expert academic support. Our services include
          assistance with essays, research papers, homework, dissertations,
          coursework, and more across various subjects. Whether you need a
          tutor, writer, or researcher, we provide access to skilled
          professionals who are ready to help you succeed in your academic
          journey.
        </div>
        <div className="Best__services_container__heading__button">
          <button>Order Now</button>
        </div>
      </div>
      <div className="Best__services__coustomer__container__img">
        <img src={service} alt="" />
      </div>
    </div>
  );
}
