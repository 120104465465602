export const faqData = [
  {
    heading: "How do I create an Account?",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium! Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium!",
  },
  {
    heading: "How do I create an Account?",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium! Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium!",
  },
  {
    heading: "How do I create an Account?",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium! Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium!",
  },
  {
    heading: "How do I create an Account?",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium! Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium!",
  },
  {
    heading: "How do I create an Account?",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium! Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium!",
  },
  {
    heading: "How do I create an Account?",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium! Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium!",
  },
  {
    heading: "How do I create an Account?",
    info: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium! Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque vel officiis mollitia doloremque ea, necessitatibus inventore voluptatem accusantium quaerat laudantium!",
  },
];
