import React from "react";
import { ourmission } from "../assets";

export function AboutOurMissionSection() {
  return (
    <div
      className="home__payments"
      style={{
        marginTop: "5em",
      }}
    >
      <img src={ourmission} alt="ourmission" className="home__payments__img" />
      <div className="home__payments__overlay">
        <div className="home__payments__overlay__heading">Our Mission</div>
        <div className="home__payments__overlay__info">
          Our mission is to create a platform that not only makes academic tasks
          easier for students, provides opportunities for experts, and
          streamlines the assignment process, but also sets a new standard in
          the industry, giving back to the educational community and leading the
          way in academic support services.
        </div>
      </div>
    </div>
  );
}
