import React from "react";
import { Zoom } from "react-reveal";
import { ourstory } from "../assets";

export function AboutOurStory() {
  return (
    <div className="about__concepts">
      <Zoom>
        <img src={ourstory} alt="ourstory" className="about__concepts__img" />
      </Zoom>
      <div
        className="about__concepts__content"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="about__concepts__content__heading">Our Story</div>
        <div className="about__concepts__content__info">
          ReadyAssignment is an innovative online platform offering expert
          academic assistance to students and professionals. Established in
          2022, we are pioneers in the academic support industry. Our vision
          goes beyond building a company; we aim to reshape the industry by
          providing accessible academic help for all. We don't just search for
          the right talent; we nurture and develop it through our comprehensive
          training programs, ensuring our experts are prepared to deliver
          top-tier educational support. At ReadyAssignment, there’s a solution
          for everyone, empowering students to succeed and excel in their
          academic endeavors.
        </div>
      </div>
    </div>
  );
}
